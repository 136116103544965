import React, {useEffect, useState} from 'react'
import Index from '../components/compare/Index'
import Menu from '../components/compare/Menu'
import './../styles/Compare.scss'
import {useNavigate} from 'react-router-dom'
import Guidance from '../components/compare/Guidance'
import Toast from 'react-bootstrap/Toast'
import {Button, ToastContainer} from 'react-bootstrap'
import RoundTrip from '../assets/icons/RoundTrip.svg'
import {getExpediaLink} from '../helpers/Expedia'
import BaselineHelper from '../helpers/Flight'
import loadingIcon from '../assets/icons/Loading.gif'

const Compare = () => {
    const [guidance, setGuidance] = useState(localStorage.getItem('guidance'))
    const [currentOffer, setCurrentOffer] = useState({})
    const [showMessage, setShowMessage] = useState('')
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const onOfferScroll = visibleOffer => {
        setCurrentOffer({offer: visibleOffer, params: state.params})
    }
    useEffect(async () => {
        const params = new URLSearchParams(window.location.search)
        const baselineId = params.get('baselineId')
        if (baselineId) {
            const result = await BaselineHelper.getBaselineById(baselineId)
                .then(result => result.data)
                .catch(() => {
                    navigate('/flight')
                    return
                })
            const offer = result.data.offer
            setState({
                inboundFlight: BaselineHelper.getInboundData(offer),
                outboundFlight: BaselineHelper.getOutboundData(offer),
                ratings: offer.ratings,
                price: offer.price.grandTotal,
                airline:
                    offer.dictionaries.carriers[result.data.props.carrierCode],
                params: result.data.props,
                offer: offer,
            })

            setLoading(false)
        } else {
            navigate('/flight')
        }
    }, [])

    const onSave = () => {
        let shortListedOffers = JSON.parse(
            localStorage.getItem('shortListedOffers')
        )
        if (!shortListedOffers) shortListedOffers = []
        const offerExists =
            shortListedOffers.filter(
                offer => currentOffer.offer[0]['id'] == offer.offer[0].id
            ).length > 0
        if (offerExists) {
            setShowMessage('Offer already exists in my list!')
        } else {
            localStorage.setItem(
                'shortListedOffers',
                JSON.stringify(shortListedOffers.concat(currentOffer))
            )
            setShowMessage('Offer added to my list!')
        }
    }
    const openExpedia = e => {
        const link = getExpediaLink(currentOffer)
        e.preventDefault()
        window.location.href = link
    }

    return (
        <>
            {loading ? (
                <div className="loading">
                    <img src={loadingIcon} alt="loading icon" />
                </div>
            ) : (
                <div className="compare-page-container">
                    {guidance === null ? (
                        <Guidance setGuidance={setGuidance} />
                    ) : (
                        ''
                    )}
                    <Menu guidance={guidance} state={state} />
                    <div className="iternary">
                        <div>{state.outboundFlight.departureCode}</div>
                        <div>
                            <img src={RoundTrip} alt="round-trip-icon" />
                        </div>
                        <div> {state.outboundFlight.arrivalCode}</div>
                    </div>
                    <Index
                        inboundFlight={state.inboundFlight}
                        outboundFlight={state.outboundFlight}
                        ratings={state.ratings}
                        guidance={guidance}
                        price={state.price}
                        airline={state.airline}
                        params={state.params}
                        offer={state.offer}
                        onOfferInFocus={onOfferScroll}
                    />
                    <div
                        className={
                            localStorage.getItem('guidance') === null
                                ? 'white-background compare-buttons'
                                : 'compare-buttons'
                        }>
                        <Button onClick={onSave} className="compare-button">
                            Save
                        </Button>
                        <Button
                            onClick={openExpedia}
                            className="compare-button">
                            Book
                        </Button>
                    </div>
                    {showMessage != '' ? (
                        <ToastContainer position="top-center">
                            <Toast
                                onClose={() => setShowMessage('')}
                                show={showMessage}
                                delay={3000}
                                autohide>
                                <Toast.Body>{showMessage}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    ) : null}
                </div>
            )}
        </>
    )
}
export default Compare
