import React, {useEffect, useState} from 'react'
import '../../styles/FindYourFlight.scss'
import TripDetails from './TripDetails'
import FlightNumberAndDetailsTab from './FlightNumberAndDetailsTab'

const FindFlight = ({state}) => {
    const [flightNumberTab, setFlightNumberTab] = useState(true)
    const [flightDetailsTab, setFlightDetailsTab] = useState(false)

    const flightNumberTabHandler = () => {
        setFlightNumberTab(true)
        setFlightDetailsTab(false)
    }
    const flightDetailsTabHandler = () => {
        setFlightDetailsTab(true)
        setFlightNumberTab(false)
    }
    useEffect(() => {
        if (state && state.data === 'Flight number') {
            setFlightNumberTab(true)
            setFlightDetailsTab(false)
        } else if (state && state.data === 'Flight details') {
            setFlightDetailsTab(true)
            setFlightNumberTab(false)
        }
    }, [])

    return (
        <div className="find-your-flight">
            <FlightNumberAndDetailsTab
                flightDetails={flightDetailsTabHandler}
                flightNumber={flightNumberTabHandler}
                flightNumberTab={flightNumberTab}
                flightDetailsTab={flightDetailsTab}
            />
            <TripDetails flightNumberTab={flightNumberTab} data={state} />
        </div>
    )
}
export default FindFlight
