import React from 'react'
import FlightCard from '../../components/MyList/FlightCard'
import '../../styles/MyList.scss'
import Button from 'react-bootstrap/Button'
import {useNavigate} from 'react-router-dom'
import {getExpediaLink} from '../../helpers/Expedia'
import {format} from '../../utils/CurrencyUtil'

function OfferCard(props) {
    const navigate = useNavigate()

    const onOfferCardClick = flight => {
        flight.ratings = props.data.flight.ratings
        flight.baselinePrice = props.data.baselinePrice
        flight.offerPrice = props.data.flight.price
        navigate('/flightDetails', {state: flight})
    }
    const subtractPrice = (base, offer) => format((base - offer).toFixed(2))

    const onBook = (offerId, e) => {
        const currentOffer = props.data.shortListedOffers.filter(
            offer => offer.offer[0].id === offerId
        )
        const link = getExpediaLink(currentOffer[0])
        e.preventDefault()
        window.location.href = link
    }

    const onRemove = offerId => {
        const filteredOffers = props.data.shortListedOffers.filter(
            offer => offer.offer[0].id != offerId
        )
        props.setShortListedOffers(filteredOffers)
        localStorage.setItem(
            'shortListedOffers',
            JSON.stringify(filteredOffers)
        )
        props.setOfferRemovedMessageVisible(true)
    }

    return (
        <div className="offer-card">
            <div className="offer-card-row-1">
                <div>Price:&nbsp;</div>
                <div className="currency">$</div>
                <div className="baseline-price">
                    <b>{format(props.data.flight.offer[0].price)}</b> (save:
                    {' $'}
                    <b>
                        {subtractPrice(
                            props.data.baselinePrice,
                            props.data.flight.offer[0].price
                        )}
                    </b>
                    )
                </div>
            </div>
            <div className="offer-card-row-2">
                Value rating:{' '}
                <b>{props.data.flight.offer[0].ratings.valueRating}%</b>
            </div>
            <div className="offer-card-row-3">
                <div
                    data-testid="offer-card"
                    onClick={() =>
                        onOfferCardClick(
                            props.data.flight.offer[0].outboundFlight
                        )
                    }>
                    <FlightCard
                        data={props.data.flight.offer[0].outboundFlight}
                    />
                </div>
                <div
                    onClick={() =>
                        onOfferCardClick(
                            props.data.flight.offer[0].inboundFlight
                        )
                    }>
                    <FlightCard
                        data={props.data.flight.offer[0].inboundFlight}
                    />
                </div>
            </div>
            <div className="my-list-btn">
                <Button
                    onClick={e => onRemove(props.data.flight.offer[0]?.id)}
                    className="my-list-remove-btn btn-sm">
                    Remove
                </Button>
                <Button
                    onClick={e => onBook(props.data.flight.offer[0]?.id, e)}
                    className="my-list-book-btn btn-sm">
                    Book
                </Button>
            </div>
            <hr />
        </div>
    )
}
export default OfferCard
