import React from 'react'
import MenuIcon from '../../assets/icons/Menu.svg'
import HeartIcon from '../../assets/icons/Heart.svg'
import {useNavigate} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import '../../styles/LeftNavigation.scss'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {useAuth0} from '@auth0/auth0-react'

const Menu = ({state}) => {
    const {isAuthenticated, loginWithPopup, logout} = useAuth0()
    const navigate = useNavigate()
    const handleClick = () => navigate('/myList', {state: state})
    const onBack = () => history.back()

    return (
        <div
            className={
                localStorage.getItem('guidance') === null
                    ? 'white-background menu-container'
                    : 'menu-container'
            }>
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose={true}
                overlay={
                    <Popover>
                        <Popover.Body className="pop-body">
                            <Button onClick={onBack} className="back-button">
                                Back
                            </Button>
                            <Button href="#" className="back-button">
                                <span className="aside__text">
                                    {isAuthenticated ? (
                                        <div
                                            onClick={() => {
                                                logout({
                                                    returnTo:
                                                        window.location.origin,
                                                })
                                            }}>
                                            Logout
                                        </div>
                                    ) : (
                                        <div onClick={loginWithPopup}>
                                            {' '}
                                            Login
                                        </div>
                                    )}
                                </span>
                            </Button>
                        </Popover.Body>
                    </Popover>
                }>
                <div>
                    {JSON.parse(process.env.REACT_APP_LOGIN) ? (
                        <div className="navigation">
                            <button
                                className="navigation__item"
                                data-testid="menu-testId">
                                <img src={MenuIcon} className="menu-icon" />
                                <div>Menu</div>
                            </button>
                        </div>
                    ) : (
                        <Button onClick={onBack} className="back-button">
                            Back
                        </Button>
                    )}
                </div>
            </OverlayTrigger>
            <div>Compare Flights</div>
            <div onClick={handleClick} className="menu-icon-text">
                <img src={HeartIcon} className="menu-icon" />
                <div className="my-list-button">
                    <button>MY LIST</button>{' '}
                </div>
            </div>
        </div>
    )
}
export default Menu
