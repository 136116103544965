import axios from 'axios'
import config from '../config'

const getFlightAlternative = async data =>
    axios.post(`${config.API_URL}v2/flight/city`, data, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
const getOffers = async data =>
    axios.post(`${config.API_URL}v2/offers`, data, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
const getFlight = async data =>
    axios.post(`${config.API_URL}v2/flight/number?`, data, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })

const getBaselineById = async id =>
    axios.get(`${config.API_URL}v2/baseline/${id}`, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })

const getAppVersion = async () =>
    axios.get(`${config.API_URL}/version?`, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })

const getAirlines = async () =>
    axios.get(`${config.API_URL}/airlines?`, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })

export default {
    getFlightAlternative,
    getFlight,
    getOffers,
    getAppVersion,
    getBaselineById,
    getAirlines,
}
