import React from 'react'
import Chair from '../../assets/icons/Chair.svg'
import ChairWithLegRoom from '../../assets/icons/ChairWithLegRoom.svg'
import ReclinedChair from '../../assets/icons/ReclinedChair.svg'
import '../../styles/MyList.scss'
import {
    getFormattedDay,
    getFormatTime,
    getFormattedMonth,
} from '../../helpers/format/DateAndTime'

const FlightCard = props => {
    return (
        <div className="flight-card">
            <div>
                <div className="card-row-1">
                    <div>{props.data?.cabin}</div>
                    <div className="airline-logo">
                        <img src={props.data?.airlineLogo} alt="airline-logo" />
                    </div>
                </div>
                <hr />
                <div className="card-row-2">
                    <div className="card-row-2-1">
                        <div>
                            {getFormattedDay(props.data?.departureDate) +
                                ', ' +
                                getFormattedMonth(
                                    props.data?.departureDate
                                )}{' '}
                        </div>
                    </div>
                    <div className="card-row-2-2">
                        <div>
                            {props.data?.departureCode +
                                '-' +
                                getFormatTime(props.data?.departureDate)}
                        </div>
                        <div>
                            {' '}
                            {props.data?.arrivalCode +
                                '-' +
                                getFormatTime(props.data?.arrivalDate)}
                        </div>
                    </div>
                </div>
                <hr />
                <div className="card-row-3">
                    <div
                        className={
                            props.data?.seat?.width ? '' : 'not-available'
                        }>
                        <img src={Chair} alt="chair" />{' '}
                        {props.data?.seat?.width}
                        {props.data?.seat?.width ? "'" : ''}
                    </div>
                    <div
                        className={
                            props.data?.seat?.recline ? '' : 'not-available'
                        }>
                        <img src={ReclinedChair} alt="reclined-chair" />{' '}
                        {props.data?.seat?.recline}
                        {props.data?.seat?.recline
                            ? '\u00b0' // degree symbol
                            : ''}
                    </div>
                    <div
                        className={
                            props.data?.seat?.pitch ? '' : 'not-available'
                        }>
                        <img src={ChairWithLegRoom} alt="chair" />{' '}
                        {props.data?.seat?.pitch}{' '}
                        {props.data?.seat?.pitch ? "'" : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FlightCard
