import API from '../api'
import {capitalizeFirstLetter} from '../helpers/format/String'
const getAirline = (iataCode, props) => {
    return iataCode && props.dictionaries.locations[iataCode].cityCode
}

const getBaselineById = async id => {
    return await API.getBaselineById(id)
}

const getOffers = async state => {
    const queryParam = {
        carrierCode: state.carrierCode,
        departureAirport: state.departureAirport,
        departureDate: state.departureDate,
        departureTime: state.departureTime,

        returnAirport: state.returnAirport,
        returnDate: state.returnDate,
        returnTime: state.returnTime,

        adults: state.adults,
        travelClass: state.travelClass.toUpperCase(),
        trip: state.trip,
        price: state.price,
        filter: state.filter,
        offer: state.offer,
    }
    return await API.getOffers(queryParam)
}

const getOutboundData = props => {
    const flightData = props.itineraries[0].segments[0]
    flightData.outbound = true
    flightData.seat = props.seats.outboundSeat
    const cabin = getCabin(
        props.itineraries[0].segments[0].id,
        props.travelerPricings[0].fareDetailsBySegment
    )
    return getFlightData(props, flightData, cabin)
}

const getInboundData = props => {
    const flightData = props.itineraries[1].segments[0]
    flightData.outbound = false
    flightData.seat = props.seats.inboundSeat
    const cabin = getCabin(
        props.itineraries[1].segments[0].id,
        props.travelerPricings[0].fareDetailsBySegment
    )
    return getFlightData(props, flightData, cabin)
}

const getBaselineFlight = async state => {
    let response
    if (state.data === 'Flight details') {
        const data = {
            carrierCode: state.carrierCode,
            departureAirport: state.departure,
            departureDate: state.departureDate,
            departureTime: state.departureTime + ':00',
            returnAirport: state.return,
            returnDate: state.returnDate,
            returnTime: state.returnTime + ':00',
            adults: state.adults,
            travelClass: state.travelClass.toUpperCase(),
            price: Number(state.price),
        }
        response = await API.getFlightAlternative(data)
    } else {
        const data = {
            carrierCode: state.carrierCode,
            departureFlightNumber: Number(state.departureFlightNumber),
            departureDate: state.departureDate,
            returnFlightNumber: Number(state.returnFlightNumber),
            returnDate: state.returnDate,
            adults: state.adults,
            travelClass: state.travelClass.toUpperCase(),
            price: Number(state.price),
        }
        response = await API.getFlight(data)
    }

    return response.data
}
function getCabin(id, fareDetailsBySegment) {
    return fareDetailsBySegment.filter(segment => segment.segmentId == id)[0]
        .cabin
}

function getFlightData(props, flightData, cabin) {
    return {
        cabin: capitalizeFirstLetter(cabin),
        departureDate: flightData.departure.at,
        arrivalDate: flightData.arrival.at,
        departureCode: flightData.departure.iataCode,
        arrivalCode: flightData.arrival.iataCode,
        departureCity: getAirline(flightData.departure.iataCode, props),
        arrivalCity: getAirline(flightData.arrival.iataCode, props),
        outbound: flightData.outbound,
        stops: flightData.numberOfStops,
        seat: flightData.seat,
        airlineLogo:
            props.airlineLogo && props.airlineLogo.length
                ? props.airlineLogo[0].url
                : '',
        carrierName: props.dictionaries.carriers[flightData.carrierCode],
        carrierCode: flightData.carrierCode,
    }
}

export default {
    getOutboundData,
    getInboundData,
    getBaselineFlight,
    getOffers,
    getBaselineById,
}
