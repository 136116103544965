import * as React from 'react'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/Home.scss'
import arrow from '../assets/icons/Arrow.svg'
import playIcon from '../assets/icons/IconRight.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useNavigate} from 'react-router-dom'

function Home() {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/flight')
    }

    return (
        <div className="container">
            <Row className="landing-page-header">
                <Col xs="11">
                    <b>PROJECT X</b>
                    <sup className="text-secondary"> ALPHA</sup> <br />
                    <div className="text-secondary">
                        The best flying experiences
                    </div>
                </Col>
            </Row>
            <div className="container-middle">
                <div className="landing-page-middle">
                    <div>Find better</div>
                    <div>seat comfort across</div>
                </div>
                <ul>
                    <li>Different Flights</li>
                    <li>Different Airlines</li>
                    <li>Different Dates</li>
                    <li>Different Classes of Services</li>
                </ul>
                <div className="rectangle-icon">
                    <div className="rectangle-1" />
                    <div className="rectangle-2" />
                    <div className="rectangle-3" />
                    <img src={arrow} alt="arrow-icon" className="arrow" />
                </div>
            </div>
            <div className="landing-page-footer">
                <Button size="lg" className="mt-2" onClick={handleClick}>
                    LET'S START
                    <span>
                        <img src={playIcon} alt="play-icon" />
                    </span>
                </Button>
            </div>
        </div>
    )
}
export default Home
