import React from 'react'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {format} from '../../utils/CurrencyUtil'

const OfferPrice = props => {
    const subtractPrice = (base, offer) =>
        format(Math.abs((base - offer).toFixed(2)))

    return (
        <div className="compare-price">
            <div className="airline-class">
                <div className="airline">
                    <img
                        src={props.data.outboundFlight.airlineLogo}
                        alt="no-image-found"
                        className="airline-logo"
                    />
                    <div className="airline-name">
                        {props.data.outboundFlight.carrierName}
                    </div>
                </div>
                <div className="cabin">{props.data.outboundFlight.cabin}</div>
            </div>
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose={true}
                overlay={
                    <Popover>
                        <Popover.Header as="h3">
                            Check out price details
                        </Popover.Header>
                        <Popover.Body className="pop-body">
                            <div>
                                <div className="pop-body-content">
                                    <div>Baseline rate</div>
                                    <div>${format(props.baselineRate)}</div>
                                </div>
                                <hr />
                                <div className="pop-body-content">
                                    <div>Rate difference</div>
                                    <div>
                                        $
                                        {subtractPrice(
                                            props.baselineRate,
                                            props.data.price
                                        )}
                                    </div>
                                </div>
                                <hr />
                                <div className="pop-body-content">
                                    <div>New rate</div>
                                    <div>${format(props.data.price)}</div>
                                </div>
                            </div>
                        </Popover.Body>
                    </Popover>
                }>
                <div className="price-details">
                    <div>${format(props.data.price)}</div>
                    <div className="price-details-row-2">
                        Check price details
                    </div>
                </div>
            </OverlayTrigger>
        </div>
    )
}
export default OfferPrice
