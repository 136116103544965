import {useState} from 'react'
import {omit} from 'lodash'

const FormValidation = callback => {
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})

    const validate = (name, value, updateData) => {
        switch (name) {
            case 'adults':
                if (!new RegExp(/^[1-9]+[0-9]*$/).test(value)) {
                    updateData(value)
                    setErrors({
                        ...errors,
                        adults: 'Invalid passenger count',
                    })
                } else {
                    updateData(value)
                    let newObj = omit(errors, 'adults')
                    setErrors(newObj)
                }
                break
            case 'carrierCode':
            case 'departure':
            case 'return':
            case 'price':
            case 'departureTime':
            case 'departureDate':
            case 'returnDate':
            case 'returnTime':
            case 'departureFlightNumber':
            case 'returnFlightNumber':
                updateData(value)
                break
            default:
                break
        }
    }

    const handleChange = (event, updateData) => {
        if (typeof event.persist !== 'undefined') event.persist()
        let name = event.target.name
        let val = event.target.value
        validate(name, val, updateData)
        setValues({
            ...values,
            [name]: val,
        })
    }

    const handleSubmit = event => {
        if (event) event.preventDefault()
        if (Object.keys(errors).length === 0) {
            if (
                new Date(values['returnDate']) <
                new Date(values['departureDate'])
            ) {
                alert('Please check the date')
            } else {
                callback()
            }
        } else {
            alert('Please check the form')
        }
    }

    return {
        values,
        errors,
        handleChange,
        handleSubmit,
    }
}

export default FormValidation
