import React, {useEffect, useState} from 'react'
import chairWithLegRoom from '../../assets/icons/ChairWithLegRoom.svg'
import noImageAvailable from '../../assets/icons/NoImageAvailable.svg'
import reclinedChair from '../../assets/icons/ReclinedChair.svg'
import chair from '../../assets/icons/Chair.svg'
import moon from '../../assets/icons/Moon.svg'
import Expand from '../../assets/icons/Expand.svg'
import Loader from '../../assets/icons/ImageLoader.gif'
import Modal from '../modal/Modal'
import Slider from '../compare/FlightImageSlider'

function FlightCard(props) {
    const [modal, setModal] = useState(false)
    const [image, setImage] = useState(Loader)
    const [sliderImage, setSliderImage] = useState(Loader)

    //TODO: Move to a date helper module
    const getFormattedDate = departureDate => {
        var days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
        var months = [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC',
        ]

        const date = new Date(departureDate)

        return (
            days[date.getDay()] +
            ', ' +
            months[date.getMonth()] +
            ' ' +
            date.getDate()
        )
    }

    useEffect(() => {
        if (props.data.seat?.images) {
            setImage(props.data.seat.images[0].url)
            setSliderImage(props.data.seat.images[0].url)
        } else {
            setImage(noImageAvailable)
        }
    }, [])

    const modalClose = () => {
        setModal(false)
        if (props.setShow) props.setShow(false)
    }
    const handleImage = () => {
        if (props.data.seat.images?.length > 1) {
            if (props.setShow) props.setShow(true)

            setModal(true)
        }
    }
    //TODO: Move to a date helper module
    function formatAMPM(date) {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
    }

    return (
        <div className="baseline-middle-flight-card">
            {props.data.seat?.images ? (
                <div className="flight-gallery">
                    <img
                        src={image}
                        className="gallery-img"
                        alt="gallery"
                        onClick={handleImage}
                    />
                    {props.data.seat.images.length > 1 ? (
                        <>
                            <img
                                src={Expand}
                                alt="preview"
                                className="image-preview"
                                onClick={handleImage}
                            />
                            <Modal
                                show={modal}
                                handleClose={e => modalClose(e)}>
                                <img
                                    className="Full-img"
                                    src={sliderImage}
                                    alt="no-image-found"
                                    onClick={e => setModal(true)}
                                />
                                <Slider
                                    images={props.data.seat.images}
                                    setImage={setSliderImage}
                                />
                            </Modal>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                <img
                    className={'gallery-not-available'}
                    src={noImageAvailable}
                    alt="no-image-found"
                />
            )}
            <div className="flight-card-list">
                <div className="flight-card-list-1">
                    <div className="cabin-class">{props.data.cabin}</div>
                    <div className="airline-logo">
                        <img src={props.data.airlineLogo} alt="class" />
                    </div>
                </div>
                <hr />
                <div className="flight-card-list-2">
                    <div className="flight-card-list-2-date">
                        {getFormattedDate(props.data.departureDate) +
                            (props.data.stops == 0
                                ? ' NON-STOP'
                                : props.data.stops + ' STOPS')}
                    </div>
                    <div className="flight-card-list-2-route">
                        {props.data.departureCode +
                            '-' +
                            formatAMPM(new Date(props.data.departureDate))}{' '}
                        <span>
                            <img src={moon} alt="icon" />
                        </span>
                        {props.data.arrivalCode +
                            '-' +
                            formatAMPM(new Date(props.data.arrivalDate))}
                    </div>
                </div>
                <hr />
                <div className="flight-card-list-3">
                    <div
                        className={
                            props.data.seat?.width ? '' : 'not-available'
                        }>
                        <img src={chair} alt="chair" /> {props.data.seat?.width}
                        {props.data.seat?.width ? "'" : ''}
                    </div>
                    <div
                        className={
                            props.data.seat?.recline ? '' : 'not-available'
                        }>
                        <img src={reclinedChair} alt="reclined-chair" />{' '}
                        {props.data.seat?.recline}
                        {props.data.seat?.recline
                            ? '\u00b0' // degree symbol
                            : ''}
                    </div>
                    <div
                        className={
                            props.data.seat?.pitch ? '' : 'not-available'
                        }>
                        <img src={chairWithLegRoom} alt="chair" />{' '}
                        {props.data.seat?.pitch}{' '}
                        {props.data.seat?.pitch ? "'" : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FlightCard
