import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/Baseline.scss'
import FlightCard from '../components/baseline/FlightCard'
import FlightPath from '../components/baseline/FlightPath'
import {useNavigate} from 'react-router-dom'
import loadingIcon from '../assets/icons/Loading.gif'
import {useLocation} from 'react-router-dom'
import BaselineHelper from '../helpers/Flight'
import {format} from '../utils/CurrencyUtil'

function Baseline() {
    const {state} = useLocation()
    const [outboundFlight, setOutboundFlight] = useState({})
    const [inboundFlight, setInboundFlight] = useState({})
    const [price, setPrice] = useState()
    const [loading, setLoading] = useState(true)
    const [baselineId, setBaselineId] = useState('')
    useEffect(async () => {
        let result
        try {
            result = await BaselineHelper.getBaselineFlight(state).then(
                result => result
            )
        } catch (error) {
            const standardError =
                'We are facing a technical issue, please try again.'
            //FIXME: Super complicated. Nested ternary operator usage is bad!
            result = {
                type: 'error',
                message:
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                        ? error.response.data.error.code === 'VALIDATION_FAILED'
                            ? 'Please enter correct values and try again'
                            : error.response.data.error.message
                            ? error.response.data.error.message
                            : standardError
                        : standardError,
            }
        }
        if (result.type == 'error') {
            alert(result.message)
            navigate('/flight', {state: state})
            return
        }
        setBaselineId(result.id)
        const offer = result.data.offer
        setOutboundFlight(BaselineHelper.getOutboundData(offer))
        setInboundFlight(BaselineHelper.getInboundData(offer))
        setPrice(offer.price.grandTotal)
        setLoading(false)
    }, [])

    const navigate = useNavigate()
    const onBack = () => {
        navigate('/flight', {state: state})
    }

    const onConfirm = async () => {
        localStorage.removeItem('shortListedOffers')
        navigate({
            pathname: '/compare',
            search: `?baselineId=${baselineId}`,
        })
    }
    return (
        <div className="baseline-root">
            <div className="baseline-header">
                <div>
                    {loading ? 'FETCHING' : 'THIS IS'} YOUR{' '}
                    <span>BASELINE TRIP</span>
                </div>
            </div>
            {loading ? (
                <div className="loading">
                    <img src={loadingIcon} alt="loading icon" />
                </div>
            ) : (
                <div className="baseline-content">
                    <div className="baseline-middle">
                        <div className="outbound">
                            <FlightPath data={outboundFlight} />
                            <FlightCard data={outboundFlight} />
                        </div>
                        <div className="inbound">
                            <FlightPath data={inboundFlight} />
                            <FlightCard data={inboundFlight} />
                        </div>
                        <div className="price">
                            <div>
                                <span>TOTAL PRICE FOUND</span>
                            </div>
                            <div>
                                <b>${format(price)}</b>
                            </div>
                        </div>
                    </div>
                    <div className="baseline-footer">
                        <div>
                            <Button
                                onClick={onBack}
                                className="baseline-footer-back-btn btn-grey">
                                BACK
                            </Button>
                        </div>
                        <div>
                            <Button
                                className="baseline-footer-confirm-btn"
                                onClick={onConfirm}>
                                CONFIRM
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Baseline
