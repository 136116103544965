import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import line from '../../assets/icons/Line.svg'
import plane from '../../assets/icons/Plane.svg'

function FlightPath(props) {
    return (
        <div className="baseline-middle-outbound">
            {props.data.outbound ? (
                <Col xs="3" lg={3} className="flight-meta">
                    <Col className="src-code">{props.data.departureCode}</Col>
                    <Col className="src-airline">
                        {props.data.departureCity}
                    </Col>
                </Col>
            ) : (
                <Col xs="3" lg={3} className="flight-meta">
                    <Col className="des-code">{props.data.arrivalCode}</Col>
                    <Col className="des-airline">{props.data.arrivalCity}</Col>
                </Col>
            )}
            <Col xs="6" lg={6}>
                {props.data.outbound ? (
                    <Col className="middle-outbound-image">
                        <img
                            className="baseline-middle-outbound-line"
                            src={line}
                            alt="line"
                        />
                        <img
                            className="baseline-middle-outbound-plane"
                            src={plane}
                            alt="plane"
                        />
                    </Col>
                ) : (
                    <Col className="middle-inbound-image">
                        <img
                            className="baseline-middle-inbound-line"
                            src={line}
                            alt="line"
                        />
                        <img
                            className="baseline-middle-inbound-plane"
                            src={plane}
                            alt="plane"
                        />
                    </Col>
                )}
                <Col className="switch">
                    {props.data.outbound ? 'OUTBOUND FLIGHT' : 'INBOUND FLIGHT'}
                </Col>
            </Col>
            {props.data.outbound ? (
                <Col xs="3" lg={3} className="flight-meta">
                    <Col className="des-code right">
                        {props.data.arrivalCode}
                    </Col>
                    <Col className="des-airline right">
                        {props.data.arrivalCity}
                    </Col>
                </Col>
            ) : (
                <Col xs="3" lg={3} className="flight-meta">
                    <Col className="src-code right">
                        {props.data.departureCode}
                    </Col>
                    <Col className="src-airline right">
                        {props.data.departureCity}
                    </Col>
                </Col>
            )}
        </div>
    )
}
export default FlightPath
