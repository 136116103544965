const dev = {
    API_URL: 'http://localhost:3000/',
    domain: 'upgradeengine-dev.us.auth0.com',
    clientId: 'NBQod7OXiDkwYTrtS4e9cw1MgZPuEWlM',
}

const prod = {
    API_URL: process.env.REACT_APP_API_URL,
    domain: 'upgradeengine.us.auth0.com',
    clientId: 'KMimqFExNfdfA7VTaLzW4GovjoAvSwKJ',
}

const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod

export default {
    ...config,
}
