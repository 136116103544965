import React from 'react'

const Modal = props => {
    const showHideClassName = props.show ? 'modal d-block' : 'modal d-none'

    return (
        <div
            className={showHideClassName}
            style={
                props.isGuidance
                    ? {
                          background: 'rgb(255 255 255 / 80%)',
                          top: '5vh',
                          height: '90vh',
                      }
                    : {background: 'rgba(0, 0, 0, 0.6)'}
            }>
            <div className={!props.isGuidance ? 'modal-container' : ''}>
                {props.children}
                {!props.isGuidance ? (
                    <div className="modal-close" onClick={props.handleClose}>
                        close
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default Modal
