import React, {useState} from 'react'

import FlightCard from '../components/MyList/FlightCard'
import OfferCard from '../components/MyList/OfferCard'
import '../../src/styles/MyList.scss'
import Button from 'react-bootstrap/Button'
import {useLocation} from 'react-router-dom'
import Toast from 'react-bootstrap/Toast'
import {useNavigate} from 'react-router-dom'

import {ToastContainer} from 'react-bootstrap'
function MyList() {
    const navigate = useNavigate()
    const {state} = useLocation()
    const [shortListedOffers, setShortListedOffers] = useState([])
    const [offerRemovedMessageVisible, setOfferRemovedMessageVisible] =
        useState(false)

    const onFlightCardClick = flight => {
        flight.baselinePrice = state.price
        navigate('/flightDetails', {state: flight})
    }
    const onBack = () => history.back()

    React.useEffect(() => {
        let shortListedOffers = JSON.parse(
            localStorage.getItem('shortListedOffers')
        )
        setShortListedOffers(shortListedOffers ?? [])
    }, [])

    return (
        <div className="my-list-container">
            <header className="row-0">
                <Button onClick={onBack} className="row-0-1">
                    Back
                </Button>
                <h3>MY LIST</h3>
            </header>
            <div className="my-list-contents">
                <div className="iternary">
                    {state.outboundFlight.departureCode} -{' '}
                    {state.outboundFlight.arrivalCode}
                </div>
                <div className="baseline-card-header">
                    <div>OUTBOUND</div>
                    <div>INBOUND</div>
                </div>
                <div className="baseline-card">
                    <div className="baseline-card-row-1">
                        <div>Price:&nbsp;</div>
                        <div className="currency">$</div>
                        <div className="baseline-price">
                            <b>{state.price}</b>
                        </div>
                    </div>
                    <div className="baseline-card-row-2">
                        <div
                            data-testid="baseline-card"
                            onClick={() =>
                                onFlightCardClick(state.outboundFlight)
                            }>
                            <FlightCard data={state.outboundFlight} />
                        </div>
                        <div
                            data-testid="baseline-card"
                            onClick={() =>
                                onFlightCardClick(state.inboundFlight)
                            }>
                            <FlightCard data={state.inboundFlight} />
                        </div>
                    </div>
                </div>
                <div className="scroll">
                    {shortListedOffers.map((flight, index) => (
                        <OfferCard
                            key={index}
                            data={{
                                flight: flight,
                                baselinePrice: state.price,
                                shortListedOffers: shortListedOffers,
                            }}
                            setShortListedOffers={setShortListedOffers}
                            setOfferRemovedMessageVisible={
                                setOfferRemovedMessageVisible
                            }
                        />
                    ))}
                </div>
            </div>
            <ToastContainer position="top-center">
                <Toast
                    onClose={() => setOfferRemovedMessageVisible(false)}
                    show={offerRemovedMessageVisible}
                    delay={3000}
                    autohide>
                    <Toast.Body>Offer removed from my list!</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}
export default MyList
