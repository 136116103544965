import React, {useEffect, useState} from 'react'
import Flight from './Flight'
import Carousel from '../carousel/Carousel'
import OfferPrice from './OfferPrice'
import RatingBreakdown from './RatingBreakdown'
import FlightHelper from '../../helpers/Flight'

const Offers = props => {
    const [show, setShow] = useState(false)
    const [visibleOfferId, setVisibleOfferId] = useState(0)
    const [loading, setLoading] = useState(true)
    const message = 'No Offers Found'

    const [offers, setOffers] = useState([])
    const getTime = dateAndTime => {
        return dateAndTime.substring(
            dateAndTime.indexOf('T') + 1,
            dateAndTime.length
        )
    }
    useEffect(async () => {
        props.data.params['departureAirport'] =
            props.data.outboundFlight.departureCode

        props.data.params['returnAirport'] =
            props.data.outboundFlight.arrivalCode

        props.data.params['departureTime'] = getTime(
            props.data.outboundFlight.departureDate
        )

        props.data.params['returnTime'] = getTime(
            props.data.inboundFlight.departureDate
        )
        props.data.params['offer'] = props.data.offer

        const result = await FlightHelper.getOffers(props.data.params)
            .then(result => result.data)
            .catch(err => {
                alert(err)
            })
        const results = []

        result.data.forEach(data => {
            if (Object.keys(data).length) {
                const outboundFlight = FlightHelper.getOutboundData(data)
                const inboundFlight = FlightHelper.getInboundData(data)
                const price = data.price.grandTotal
                const ratings = data.ratings
                results.push({
                    outboundFlight,
                    inboundFlight,
                    price,
                    ratings,
                })
            }
        })

        setOffers(results)
        setLoading(false)
    }, [])

    React.useEffect(async () => {
        props.onOfferInFocus(
            offers.filter(offer => offer.id === visibleOfferId)
        )
    }, [offers, visibleOfferId, loading])

    return (
        <div className="column">
            {loading ? (
                <p className="fetching">
                    Fetching offers<span>.</span>
                    <span>.</span>
                    <span>.</span>
                </p>
            ) : (
                <Carousel
                    frameSize={1}
                    show={show}
                    message={message}
                    setVisibleOfferId={setVisibleOfferId}>
                    {offers.map((offer, index) => {
                        offer.id = index
                        return (
                            <div className="listOne" key={index}>
                                <RatingBreakdown data={offer} />
                                <OfferPrice
                                    data={offer}
                                    baselineRate={props.data.offer.price.total}
                                />
                                <div className="compare-line">Outbound</div>
                                <Flight
                                    data={offer.outboundFlight}
                                    setShow={setShow}
                                    isLeftSide={false}
                                />
                                <div
                                    className="compare-line"
                                    data-testid="offers-inbound">
                                    Inbound
                                </div>
                                <Flight
                                    data={offer.inboundFlight}
                                    setShow={setShow}
                                    isLeftSide={false}
                                    isBottom={true}
                                />
                            </div>
                        )
                    })}
                </Carousel>
            )}
        </div>
    )
}
export default Offers
