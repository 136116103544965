export const getExpediaLink = offers => {
    const offer = offers.offer
    const params = offers.params
    const outboundDate = new Date(offer[0].outboundFlight.departureDate)
    const departureTimeOfDay = getTimeOfDay(outboundDate.getHours())
    const departureDate = params.departureDate.split('-')
    const returnDate = params.returnDate.split('-')
    const link = `https://www.expedia.com/Flights-Search?filters=[{"numOfStopFilterValue":{"stopInfo":{"stopFilterOperation":"EQUAL","numberOfStops":0}}},{"preferredAirlineFilterValue":{"carrierCode":"${
        offer[0].inboundFlight.carrierCode
    }"}},{"departureTimeFilterValue":{"timeOfDay":"${departureTimeOfDay}"}}]&leg1=from:${
        params.departureAirport
    },to:${params.returnAirport},departure:${
        departureDate[1] + '/' + departureDate[2] + '/' + departureDate[0]
    }TANYT&leg2=from:${params.returnAirport},to:${
        params.departureAirport
    },departure:${
        returnDate[1] + '/' + returnDate[2] + '/' + returnDate[0]
    }ANYT&mode=search&options=carrier:\*,cabinclass:${offer[0].inboundFlight.cabin.toLowerCase()},maxhops:0,nopenalty:N&pageId=0&passengers=adults:${
        params.adults
    },children:0,infantinlap:N&sortOrder=INCREASING&sortType=PRICE&trip=roundtrip`
    return link
}
const getTimeOfDay = time => {
    let timeOfDay = ''
    if (time >= 0 && time < 5) {
        timeOfDay = 'EARLY_MORNING'
    } else if (time >= 5 && time < 12) {
        timeOfDay = 'MORNING'
    } else if (time >= 12 && time < 18) {
        timeOfDay = 'AFTERNOON'
    } else {
        timeOfDay = 'EVENING'
    }
    return timeOfDay
}
