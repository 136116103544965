import React from 'react'
import Baseline from './Baseline'
import Offers from './Offers'
import './../../styles/Compare.scss'

const Index = props => {
    return (
        <div className="compare-row">
            <Baseline data={props} />
            <Offers data={props} onOfferInFocus={props.onOfferInFocus} />
        </div>
    )
}
export default Index
