import React, {useEffect, useState} from 'react'
import Modal from '../modal/Modal'
import './../../styles/Modal.scss'
import Slider from './FlightImageSlider'
import NoImageAvailable from '../../assets/icons/NoImageAvailable.svg'
import Expand from '../../assets/icons/Expand.svg'
import Loader from '../../assets/icons/ImageLoader.gif'

import {
    getFormattedDay,
    getFormatTime,
    getFormattedMonth,
} from '../../helpers/format/DateAndTime'
const defaultProps = {
    isLeftSide: true,
}

const flight = props => {
    props = {...defaultProps, ...props}
    const [modal, setModal] = useState(false)
    const [image, setImage] = useState(Loader)
    const [sliderImage, setSliderImage] = useState(Loader)

    useEffect(() => {
        if (props.data.seat?.images) {
            setImage(props.data.seat.images[0].url)
            setSliderImage(props.data.seat.images[0].url)
        } else {
            setImage(NoImageAvailable)
        }
    }, [])

    const modalClose = () => {
        setModal(false)
        if (props.setShow) props.setShow(false)
    }
    const handleImage = () => {
        if (props.data.seat.images?.length > 1) {
            if (props.setShow) props.setShow(true)

            setModal(true)
        }
    }

    return (
        <div className="baseline-container">
            <div className="baseline-text-img">
                <div className="baseline-text">
                    {props.isBottom ? (
                        <div className="baseline-day">{props.data.cabin}</div>
                    ) : (
                        ''
                    )}

                    <div className="baseline-day">
                        {getFormattedDay(props.data.departureDate)}
                    </div>
                    <div className="baseline-date">
                        {getFormattedMonth(props.data.departureDate)}
                    </div>
                    <div className="baseline-small">
                        {props.data.departureCode}
                    </div>
                    <div className="baseline-small">
                        {getFormatTime(new Date(props.data.departureDate))}
                    </div>
                    <div className="baseline-small">
                        {props.data.stops == 0
                            ? ' NON-STOP'
                            : props.data.stops + ' STOPS'}
                    </div>
                </div>
                <div className="baseline-img">
                    {props.data.seat?.images ? (
                        <>
                            <img
                                className="compare-img"
                                src={image}
                                alt="image"
                                onClick={handleImage}
                            />
                            {props.data.seat.images.length > 1 ? (
                                <>
                                    <img
                                        src={Expand}
                                        alt="preview"
                                        className="image-preview"
                                        onClick={handleImage}
                                    />
                                    <Modal
                                        show={modal}
                                        handleClose={e => modalClose(e)}>
                                        <img
                                            className="Full-img"
                                            src={sliderImage}
                                            alt="no-image-found"
                                            onClick={e => setModal(true)}
                                        />
                                        <Slider
                                            images={props.data.seat.images}
                                            setImage={setSliderImage}
                                        />
                                    </Modal>
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        <img
                            src={image}
                            alt="No Image Available"
                            className="compare-img compare-no-img"
                        />
                    )}
                </div>
            </div>
            <div className="baseline-seat">
                {props.isLeftSide ? (
                    <div>
                        <div className=".seat-small">Seat width</div>
                        <div className=".seat-small">Recline angle</div>
                        <div className=".seat-small">Seat pitch</div>
                    </div>
                ) : (
                    ''
                )}
                <div>
                    <div className="baseline-seat">
                        {props.data.seat?.width
                            ? props.data.seat.width + '"'
                            : '-'}
                    </div>
                    <div className="baseline-angle">
                        {props.data.seat?.recline
                            ? props.data.seat.recline + '"'
                            : '-'}
                    </div>
                    <div className="baseline-pitch">
                        {props.data.seat?.pitch
                            ? props.data.seat.pitch + '"'
                            : '-'}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default flight
