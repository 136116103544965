import React from 'react'
import Flight from './Flight'
import {format} from '../../utils/CurrencyUtil'

const Baseline = props => {
    return (
        <div className="column">
            <div className="column-trip">Your round trip</div>
            <div className="compare-price">
                <div className="airline-class">
                    <div className="airline">
                        <div>
                            <img
                                src={props.data.inboundFlight.airlineLogo}
                                alt="no-image-found"
                                className="airline-logo"
                            />
                        </div>
                        <div className="airline-name">{props.data.airline}</div>
                    </div>
                    <div className="cabin">
                        {props.data.inboundFlight.cabin}
                    </div>
                </div>
                <div className="price-details">${format(props.data.price)}</div>
            </div>
            <div className="compare-line"> Outbound</div>
            <Flight data={props.data.outboundFlight} />
            <div className="compare-line">Inbound</div>
            <Flight data={props.data.inboundFlight} />
        </div>
    )
}
export default Baseline
