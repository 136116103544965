import React, {useState, useEffect} from 'react'

import '../styles/Version.scss'
import API from '../api'
import Button from 'react-bootstrap/Button'

import {version} from '../../package.json'
const onBack = () => history.back()

function Version() {
    const [appVersion, setAppVersion] = useState({})
    useEffect(async () => {
        const response = await API.getAppVersion()
        setAppVersion(response?.data)
    }, [])
    return (
        <div className="version-container">
            <div className="row-1">
                <h2>APP VERSION</h2>
            </div>
            <div>
                <b>Frontend: </b>
                {version + ' (' + process.env.REACT_APP_GIT_SHA})
            </div>
            <div>
                <b>Backend: </b>
                {appVersion?.packageVersion + ' (' + appVersion?.gitSHA})
            </div>
            <div className="row-4">
                <Button onClick={onBack} className="back-button">
                    Back
                </Button>
            </div>
        </div>
    )
}
export default Version
