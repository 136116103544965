import Routes from './Routes'
import React from 'react'
import {BrowserView, MobileView} from 'react-device-detect'
import {useEffect, useState} from 'react'

const App = () => {
    const [horizontal, setHorizontal] = useState(false)
    const styles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        textAlign: 'center',
        transform: 'translateX(-50%) translateY(-50%)',
    }

    useEffect(() => {
        setHorizontal(window.innerWidth > window.innerHeight)
        window.addEventListener('orientationchange', event => {
            setHorizontal(
                event.target.screen.availWidth > event.target.screen.availHeight
            )
        })
    }, [])

    return (
        <>
            <BrowserView style={styles} data-testid="browser-message">
                This site works only on mobile phones or tablets
            </BrowserView>
            <MobileView>
                {horizontal ? (
                    <div style={styles}>
                        <strong>
                            This site works only in portrait mode for optimal
                            experience.
                        </strong>
                        <br></br>Please turn your device orientation now.
                    </div>
                ) : (
                    <Routes />
                )}
            </MobileView>
        </>
    )
}
export default App
