import React, {useEffect, useState, useRef} from 'react'
import '../../styles/FlightDetails.scss'
import circle from '../../assets/icons/CircleHashtag.svg'
import date from '../../assets/icons/Date.svg'
import business from '../../assets/icons/Business.svg'
import dollar from '../../assets/icons/Dollar.svg'
import iconRight from '../../assets/icons/IconRight.svg'
import location from '../../assets/icons/Location.svg'
import time from '../../assets/icons/Time.svg'
const FlightDetails = props => {
    const {handleChange, airlineCode, flightNumberTab, data} = props
    const [selectedDepartureAirport, setSelectedDepartureAirport] = useState({})
    const [selectedArrivalAirport, setSelectedArrivalAirport] = useState({})
    const departureAirportRef = useRef(null)
    const [departureDate, setDepartureDate] = useState()
    const [departureTime, setDepartureTime] = useState()
    const [returnFlightNumber, setReturnFlightNumber] = useState()
    const [returnDate, setReturnDate] = useState()
    const [returnTime, setReturnTime] = useState()
    const [price, setPrice] = useState()
    const classRef = useRef(null)
    const [departureFlightNumber, setDepartureFlightNumber] = useState()
    const returnAirportRef = useRef(null)

    const airports = [
        {
            airport: 'JFK',
            country: 'USA',
            label: 'New York, JFK',
        },
        {
            airport: 'MIA',
            country: 'USA',
            label: 'Miami, MIA',
        },
        {
            airport: 'LHR',
            country: 'UK',
            label: 'London, LHR',
        },
        {
            airport: 'CDG',
            country: 'FRANCE',
            label: 'Paris, CDG',
        },
        {
            airport: 'FRA',
            country: 'GERMANY',
            label: 'Frankfurt, FRA',
        },
    ]
    const classes = [
        {value: 'Business', label: 'Business'},
        {value: 'First', label: 'First'},
        {value: 'PREMIUM_ECONOMY', label: 'Premium Economy'},
        {value: 'Economy', label: 'Economy'},
    ]
    const departureAirportOptions = airports.map(v => (
        <option
            value={v.airport}
            key={v.airport}
            disabled={
                v.airport === selectedArrivalAirport.airport ||
                v.country === selectedArrivalAirport.country
            }
            data-testid="departure-option">
            {v.label}
        </option>
    ))
    const arrivalAirportOptions = airports.map(v => (
        <option
            value={v.airport}
            key={v.airport}
            disabled={
                v.airport === selectedDepartureAirport.airport ||
                v.country === selectedDepartureAirport.country
            }
            data-testid="arrival-option">
            {v.label}
        </option>
    ))
    const classOptions = classes.map(v => (
        <option value={v.value} key={v.value} data-testid="cabin-option">
            {v.label}
        </option>
    ))
    const updateDepartureAirport = value => {
        const airport = airports.filter(v => v.airport == value)
        setSelectedDepartureAirport(airport.pop())
    }
    const updateArrivalAirport = value => {
        const airport = airports.filter(v => v.airport == value)
        setSelectedArrivalAirport(airport.pop())
    }
    useEffect(() => {
        if (data) {
            if (data.data === 'Flight details' && !flightNumberTab) {
                departureAirportRef.current.value = data.departure
                returnAirportRef.current.value = data.return
                updateDepartureAirport(data.departure)
                updateArrivalAirport(data.return)
                setReturnTime(data.returnTime)
                setDepartureTime(data.departureTime)
            } else {
                setDepartureFlightNumber(data.departureFlightNumber)
                setReturnFlightNumber(data.returnFlightNumber)
            }
            classRef.current.value = data.travelClass
            setPrice(data.price)
            setDepartureDate(data.departureDate)
            setReturnDate(data.returnDate)
        }
    }, [flightNumberTab])

    return (
        <div className="details-container">
            <div className="details">
                <div className="details__heading">Departure</div>
                {flightNumberTab ? (
                    <div className="details__selector">
                        <img
                            src={circle}
                            className="details__selector__icon"
                            alt="circle hashtag"
                        />
                        <span
                            className="details__selector__code"
                            data-testid="airlineCode">
                            {airlineCode}
                        </span>
                        <input
                            type="number"
                            className="details__selector__airline"
                            data-testid="departureFlightNumber"
                            name="departureFlightNumber"
                            placeholder="Flight Number"
                            value={departureFlightNumber}
                            onChange={e => {
                                handleChange(e, setDepartureFlightNumber)
                            }}
                            min={1}
                            max={9999}
                            required
                        />
                    </div>
                ) : (
                    <div className="details__selector">
                        <img
                            src={location}
                            className="details__selector__icon"
                            alt="circle hashtag"
                        />
                        <select
                            className="details__selector__text"
                            data-testid="departureAirportSelect"
                            name="departure"
                            onChange={e => {
                                handleChange(e, updateDepartureAirport)
                            }}
                            ref={departureAirportRef}
                            defaultValue={'DEFAULT'}>
                            <option value="DEFAULT" hidden disabled>
                                Choose Airport
                            </option>
                            {departureAirportOptions}
                        </select>
                    </div>
                )}
                <div className="details__selector">
                    <img
                        src={date}
                        className="details__selector__icon"
                        alt="circle hashtag"
                    />
                    <div className="details__selector__text">
                        <input
                            type="date"
                            name="departureDate"
                            className="details__selector__text date"
                            data-testid="departureDate"
                            value={departureDate}
                            onChange={e => {
                                handleChange(e, setDepartureDate)
                            }}
                            required
                        />
                    </div>
                </div>
                {!flightNumberTab ? (
                    <div className="details__selector">
                        <img
                            src={time}
                            className="details__selector__icon"
                            alt="time"
                        />
                        <div className="details__selector__text">
                            <input
                                type="time"
                                name="departureTime"
                                className="details__selector__text"
                                data-testid="departureTime"
                                value={departureTime}
                                onChange={e => {
                                    handleChange(e, setDepartureTime)
                                }}
                                required
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className="details">
                <div className="details__heading">Return</div>
                {flightNumberTab ? (
                    <div className="details__selector">
                        <img
                            src={circle}
                            className="details__selector__icon"
                            alt="circle hashtag"
                        />
                        <span
                            className="details__selector__code"
                            data-testid="airlineCode">
                            {airlineCode}
                        </span>
                        <input
                            type="number"
                            className="details__selector__airline"
                            name="returnFlightNumber"
                            minLength={1}
                            maxLength={4}
                            value={returnFlightNumber}
                            onChange={e => {
                                handleChange(e, setReturnFlightNumber)
                            }}
                            placeholder="Flight Number"
                            required
                        />
                    </div>
                ) : (
                    <div className="details__selector">
                        <img
                            src={location}
                            className="details__selector__icon"
                            alt="circle hashtag"
                        />
                        <select
                            className="details__selector__text"
                            data-testid="arrivalAirportSelect"
                            name="return"
                            onChange={e => {
                                handleChange(e, updateArrivalAirport)
                            }}
                            defaultValue={'DEFAULT'}
                            ref={returnAirportRef}>
                            <option value="DEFAULT" hidden disabled>
                                Choose Airport
                            </option>
                            {arrivalAirportOptions}
                        </select>
                    </div>
                )}
                <div className="details__selector">
                    <img
                        src={date}
                        className="details__selector__icon"
                        alt="circle hashtag"
                    />
                    <div className="details__selector__text">
                        <input
                            type="date"
                            name="returnDate"
                            className="details__selector__text date"
                            data-testid="returnDate"
                            onChange={e => {
                                handleChange(e, setReturnDate)
                            }}
                            value={returnDate}
                            required
                        />
                    </div>
                </div>
                {!flightNumberTab ? (
                    <div className="details__selector">
                        <img
                            src={time}
                            className="details__selector__icon"
                            alt="time"
                        />
                        <div className="details__selector__text">
                            <input
                                type="time"
                                name="returnTime"
                                className="details__selector__text"
                                data-testid="returnTime"
                                onChange={e => {
                                    handleChange(e, setReturnTime)
                                }}
                                value={returnTime}
                                required
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className="details">
                <div className="details__heading">Class</div>
                <div className="details__selector">
                    <img
                        src={business}
                        className="details__selector__icon"
                        alt="circle hashtag"
                    />
                    <select
                        className="details__selector__text"
                        data-testid="class"
                        name="travelClass"
                        onChange={handleChange}
                        defaultValue={'DEFAULT'}
                        ref={classRef}>
                        <option value="DEFAULT" hidden disabled>
                            Choose Class
                        </option>
                        {classOptions}
                    </select>
                </div>
            </div>
            <div className="details">
                <div className="details__heading">Price per passenger</div>
                <div className="details__selector">
                    <img
                        src={dollar}
                        className="details__selector__icon"
                        alt="circle hashtag"
                    />
                    <input
                        type="number"
                        className="details__selector__text"
                        name="price"
                        value={price}
                        onChange={e => {
                            handleChange(e, setPrice)
                        }}
                        placeholder="Ex: 1200"
                        required
                    />
                </div>
            </div>
            <div className="footer">
                <button className="booking-button" id="submit" value="Submit">
                    Elevate flying experience
                    <img
                        src={iconRight}
                        className="icon-right"
                        alt="circle hashtag"
                    />
                </button>
            </div>
        </div>
    )
}
export default FlightDetails
