import React from 'react'
import '../styles/FindYourFlight.scss'
import Index from '../components/findFlight/FindFlight'
import {useLocation} from 'react-router-dom'

const FindFlight = () => {
    const {state} = useLocation()

    return (
        <div className="find-your-flight">
            <Index state={state} />
        </div>
    )
}
export default FindFlight
