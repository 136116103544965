import Carousel from '../carousel/Carousel'
import React from 'react'

const FlightImageSlider = ({images, setImage}) => {
    return (
        <div
            style={{
                maxWidth: 1200,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 64,
            }}>
            <Carousel frameSize={3}>
                {images.map((image, index) => {
                    return (
                        <div
                            style={{padding: 8}}
                            key={index}
                            data-testid="image"
                            onClick={e => setImage(image.url)}>
                            <img
                                className="child-image"
                                src={image.url}
                                alt={`image ${index}`}
                                style={{width: '100%'}}
                                data-testid={`image ${index + 1}`}
                            />
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default FlightImageSlider
