import React, {useState, useRef, useEffect} from 'react'
import circle from '../../assets/icons/Circle.svg'
import roundTrip from '../../assets/icons/RoundTrip.svg'
import BookingDetails from './BookingDetails'

const TripDetails = ({flightNumberTab, data}) => {
    const tripHeadlines = ['How will your trip be?', 'TRIP DETAILS']
    const [HeadlineIndex, setHeadlineIndex] = useState(0)
    const [tripSelected, setIsTripSelected] = useState(false)
    const selectedOptionRef = useRef(null)
    const [trip, setTrip] = useState()

    const onChangeOption = e => {
        setTrip(e.target.value)
        if (e.target.value == 'round-trip') {
            setIsTripSelected(true)
            setHeadlineIndex(1)
        } else {
            setIsTripSelected(false)
            setHeadlineIndex(0)
        }
    }
    useEffect(() => {
        if (data) {
            setIsTripSelected(true)
            setHeadlineIndex(1)
            setTrip(data.trip)
            selectedOptionRef.current.value = data.trip
        }
    }, [])

    return (
        <div className="trip-details sub">
            <div className="find-your-flight__trip-heading">
                {tripHeadlines[HeadlineIndex]}
            </div>
            <div
                className={`find-your-flight__select-trip ${
                    tripSelected ? 'selected' : ''
                } `}>
                <img
                    src={tripSelected ? roundTrip : circle}
                    className={`find-your-flight__select-trip__right-arrow details__selector__icon `}
                    alt="circle right arrow"
                />
                <select
                    className={`find-your-flight__select-trip__text ${
                        tripSelected ? 'selected-text' : ''
                    } `}
                    ref={selectedOptionRef}
                    name="selector"
                    data-testid="trip-selector"
                    onChange={onChangeOption}
                    defaultValue={'DEFAULT'}>
                    <option value="DEFAULT" hidden disabled>
                        Select your trip
                    </option>
                    <option value="0" disabled data-testid="select-option">
                        One-way - Coming soon
                    </option>
                    <option value="round-trip" data-testid="select-option">
                        Round-trip
                    </option>
                    <option value="2" disabled data-testid="select-option">
                        Multi-city - Coming soon
                    </option>
                </select>
            </div>
            {tripSelected ? (
                <BookingDetails
                    trip={trip}
                    flightNumberTab={flightNumberTab}
                    data={data}
                />
            ) : (
                ''
            )}
        </div>
    )
}
export default TripDetails
