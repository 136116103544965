import React from 'react'
import '../../styles/FindYourFlight.scss'
import hashtag from '../../assets/icons/Hashtag.svg'
import search from '../../assets/icons/Search.svg'
const FlightNumberAndDetailsTab = ({
    flightNumber,
    flightDetails,
    flightDetailsTab,
    flightNumberTab,
}) => {
    return (
        <div className="trip-details">
            <div className="find-your-flight__heading">Find your flight</div>
            <div className="find-your-flight__buttons-toolbar">
                <button
                    className={`find-your-flight__buttons-toolbar__left-group ${
                        flightNumberTab ? 'selected' : ''
                    }`}
                    onClick={flightNumber}>
                    <div className="flight-number">
                        <img
                            src={hashtag}
                            className={`flight-number__hashtag-img ${
                                flightNumberTab ? 'selected-icon' : ''
                            }`}
                            alt="hashtag"
                        />
                        FLIGHT NUMBER
                    </div>
                </button>
                <button
                    className={`find-your-flight__buttons-toolbar__right-group ${
                        flightDetailsTab ? 'selected' : ''
                    }`}
                    onClick={flightDetails}>
                    <div className="flight-details">
                        <img
                            src={search}
                            alt="search icon"
                            className={`flight-details__search-img ${
                                flightDetailsTab ? 'selected-icon' : ''
                            }`}
                        />
                        FLIGHT DETAILS
                    </div>
                </button>
            </div>
        </div>
    )
}
export default FlightNumberAndDetailsTab
