import React, {useEffect, useState, useRef} from 'react'
import passengers from '../../assets/icons/Passengers.svg'
import minus from '../../assets/icons/Minus.svg'
import plus from '../../assets/icons/Plus.svg'
import airline from '../../assets/icons/Airlines.svg'
import FlightDetails from './FlightDetails'
import formValidation from '../../hooks/FormValidation'
import {useNavigate} from 'react-router-dom'
import {omit, find} from 'lodash'
import API from '../../api'
import loadingIcon from '../../assets/icons/Loading.gif'
import {ReactSearchAutocomplete} from 'react-search-autocomplete'

const BookingDetails = props => {
    const {data, trip, flightNumberTab} = props
    const navigate = useNavigate()
    const [adults, setAdults] = useState(1)
    const [loading, setLoading] = React.useState(true)
    const [selectedAirline, setSelectedAirline] = useState('')
    const [airlineOptions, setAirlineOptions] = React.useState([])
    const [airlineCode, setAirlineCode] = useState('')

    const incrementItem = () => {
        setAdults(Number(adults) + 1)
    }
    const decreaseItem = () => {
        if (adults - 1 != 0) {
            setAdults(adults - 1)
        }
    }
    const updateAdults = count => {
        setAdults(count)
    }
    const updateAirlineCode = code => {
        setAirlineCode(code)
    }
    const formatAndSortAlphabetically = response => {
        const airlines = []
        response.data.data.map(airline =>
            airlines.push({
                value: airline.fields.IATA,
                name: airline.fields.Name,
            })
        )
        let sortedAirlineOptions = airlines.sort(function (a, b) {
            var textA = a.name.toLowerCase()
            var textB = b.name.toLowerCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
        })

        return sortedAirlineOptions
    }
    useEffect(async () => {
        try {
            const response = await API.getAirlines()
            setAirlineOptions(formatAndSortAlphabetically(response))
            setLoading(false)
        } catch (error) {
            alert(error)
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (data) {
            setSelectedAirline(
                find(airlineOptions, function (airline) {
                    return airline.value === data.carrierCode
                })?.name
            )
            setAdults(data.adults)
            setAirlineCode(data.carrierCode)
        }
    }, [airlineOptions])
    const formSubmit = () => {
        let allValues = omit(values, 'data')
        let formFilled = false
        if (data) {
            if (Object.keys(values).length) {
                Object.entries(values).forEach(([key, value]) => {
                    data[key] = value
                })
            }
            allValues = omit(data, 'data')
        }
        allValues = {
            ...allValues,
            trip: trip,
            adults: adults,
        }
        if (
            allValues.adults &&
            allValues.carrierCode &&
            allValues.returnDate &&
            allValues.travelClass &&
            allValues.price
        ) {
            if (
                flightNumberTab &&
                allValues.departureFlightNumber &&
                allValues.returnFlightNumber
            ) {
                allValues['data'] = 'Flight number'
                allValues = omit(allValues, 'departure')
                allValues = omit(allValues, 'return')
                allValues = omit(allValues, 'departureTime')
                allValues = omit(allValues, 'returnTime')
                formFilled = true
            } else if (
                allValues.departure &&
                allValues.return &&
                allValues.departureTime &&
                allValues.returnTime
            ) {
                allValues['data'] = 'Flight details'
                allValues = omit(allValues, 'departureFlightNumber')
                allValues = omit(allValues, 'returnFlightNumber')
                formFilled = true
            }
        }
        formFilled
            ? navigate('/baseline', {state: allValues})
            : alert('Please fill in the required information')
    }
    const {handleChange, values, errors, handleSubmit} =
        formValidation(formSubmit)

    return loading ? (
        <div className="loading">
            <img src={loadingIcon} alt="loading icon" />
        </div>
    ) : (
        <form className="trip-modal" onSubmit={handleSubmit}>
            <div className="find-your-flight__select-trip">
                <img
                    src={passengers}
                    className="find-your-flight__select-trip__right-arrow details__selector__icon"
                    alt="circle right arrow"
                />
                <div className="find-your-flight__select-trip__text">
                    <div className="extra-margin">Passengers</div>
                </div>
                <div className="filler"></div>
                <div className="number">
                    <img
                        src={minus}
                        className="minus"
                        alt="minus"
                        onClick={decreaseItem}
                    />
                    <input
                        type="text"
                        name="adults"
                        value={adults}
                        className="number-field"
                        onChange={e => handleChange(e, updateAdults)}
                        data-testid="passenger-count"
                        required
                    />
                    <img
                        src={plus}
                        className="plus"
                        alt="plus"
                        onClick={incrementItem}
                    />
                </div>
            </div>
            <div className="find-your-flight__trip-heading">AIRLINE</div>
            <div className="find-your-flight__select-trip">
                <img
                    src={airline}
                    className="find-your-flight__select-trip__right-arrow details__selector__icon"
                    alt="circle right arrow"
                />
                <div style={{width: 400}}>
                    <ReactSearchAutocomplete
                        items={airlineOptions}
                        inputSearchString={selectedAirline}
                        placeholder="Choose Airlines"
                        onSelect={item => {
                            let event = {
                                target: {
                                    name: 'carrierCode',
                                    value: item.value,
                                },
                            }
                            handleChange(event, updateAirlineCode)
                        }}
                        autoFocus
                    />
                </div>
            </div>
            {errors.airline && <div className="error">{errors.airline}</div>}
            <FlightDetails
                handleChange={handleChange}
                airlineCode={airlineCode}
                flightNumberTab={flightNumberTab}
                data={data}
            />
        </form>
    )
}
export default BookingDetails
