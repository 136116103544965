import React from 'react'

import '../styles/MyListFlightDetails.scss'
import NoImageAvailable from '../assets/icons/NoImageAvailable.svg'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Carousel from 'react-bootstrap/Carousel'
import Chair from '../assets/icons/Chair.svg'
import ChairWithLegRoom from '../assets/icons/ChairWithLegRoom.svg'
import ReclinedChair from '../assets/icons/ReclinedChair.svg'
import {
    getFormattedDay,
    getFormatTime,
    getFormattedMonth,
} from '../helpers/format/DateAndTime'
import {useLocation} from 'react-router-dom'
import Button from 'react-bootstrap/Button'

function MyListFlightDetails() {
    const {state} = useLocation()
    const [flight, setFlight] = React.useState({})

    React.useEffect(() => {
        setFlight(state)
    })

    const onBack = () => history.back()

    return (
        <div className="flight-details-container">
            <header className="row-0">
                <Button onClick={onBack} className="row-0-1">
                    Back
                </Button>
                <h3>FLIGHT DETAILS</h3>
            </header>
            <Carousel className="row-1">
                {flight?.seat?.images?.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="flight-image"
                            data-testid="test-flight-image"
                            src={image.url}
                            alt="seat-image"
                        />
                    </Carousel.Item>
                )) ?? (
                    <Carousel.Item
                        className="no-image"
                        data-testid="test-no-image">
                        <img
                            className="w-50"
                            src={NoImageAvailable}
                            alt="no-image"
                        />
                    </Carousel.Item>
                )}
            </Carousel>
            {flight?.ratings ? (
                <div className="row-2">
                    <div
                        className="rating-label"
                        data-testid="test-rating-label">
                        VALUE RATING
                    </div>
                    <div className="rating-progress">
                        <ProgressBar
                            className="progress-bar"
                            variant="success"
                            now={Math.abs(flight?.ratings?.valueRating)}
                        />
                        <div> {flight?.ratings?.valueRating}%</div>
                    </div>
                </div>
            ) : null}
            <div className="row-3">
                <div className="row-3-1">
                    <div className="row-3-1-1">
                        <img src={flight?.airlineLogo} alt="airline-image" />
                    </div>
                    <div className="row-3-1-2">
                        <div>{flight?.carrierName}</div>
                        <div>{flight?.cabin}</div>
                    </div>
                </div>
                <div className="row-3-2">
                    {flight?.offerPrice ? (
                        <div className="row-3-2-1">
                            save ${flight?.baselinePrice - flight?.offerPrice}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="row-3-2-2" data-testid="row-3-2-2">
                        ${flight?.offerPrice ?? flight?.baselinePrice}
                    </div>
                </div>
            </div>
            <div className="row-4" data-testid="test-row-4">
                <div className="row-4-1" data-testid="test-row-4-1">
                    {flight?.stops == 0 ? 'NON-STOP' : flight?.stops + ' STOPS'}
                </div>
                <div className="row-4-2" data-testid="test-row-4-2">
                    {getFormattedDay(flight?.departureDate) +
                        ', ' +
                        getFormattedMonth(flight?.departureDate)}
                </div>
                <div className="row-4-3" data-testid="test-row-4-3">
                    <div>
                        {flight?.departureCode +
                            '-' +
                            getFormatTime(flight?.departureDate)}
                    </div>
                    <div>
                        {' '}
                        {flight?.arrivalCode +
                            '-' +
                            getFormatTime(flight?.arrivalDate)}
                    </div>
                </div>
                <hr />
            </div>
            <div className="row-5" data-testid="test-row-5">
                <div
                    data-testid="test-width"
                    className={flight?.seat?.width ? '' : 'not-available'}>
                    <img src={Chair} alt="chair" /> {flight?.seat?.width}
                    {flight?.seat?.width ? "'" : ''}
                    {flight?.seat?.width && flight?.ratings ? (
                        <div className="rating-progress">
                            <ProgressBar
                                className="progress-bar"
                                variant="success"
                                now={Math.abs(
                                    flight?.ratings?.comfortRating?.width
                                )}
                            />
                            <div> {flight?.ratings?.comfortRating.width}%</div>
                        </div>
                    ) : null}
                </div>

                <div
                    data-testid="test-recline"
                    className={flight?.seat?.recline ? '' : 'not-available'}>
                    <img src={ReclinedChair} alt="reclined-chair" />{' '}
                    {flight?.seat?.recline}
                    {flight?.seat?.recline
                        ? '\u00b0' // degree symbol
                        : ''}
                    {flight?.seat?.recline && flight?.ratings ? (
                        <div className="rating-progress">
                            <ProgressBar
                                className="progress-bar"
                                variant="success"
                                now={Math.abs(
                                    flight?.ratings?.comfortRating?.recline
                                )}
                            />
                            <div>
                                {' '}
                                {flight?.ratings?.comfortRating.recline}%
                            </div>
                        </div>
                    ) : null}
                </div>
                <div
                    data-testid="test-pitch"
                    className={flight?.seat?.pitch ? '' : 'not-available'}>
                    <img src={ChairWithLegRoom} alt="chair" />{' '}
                    {flight?.seat?.pitch}
                    {flight?.seat?.pitch ? "'" : ''}
                    {flight?.seat?.pitch && flight?.ratings ? (
                        <div className="rating-progress">
                            <ProgressBar
                                className="progress-bar"
                                variant="success"
                                now={Math.abs(
                                    flight?.ratings?.comfortRating?.pitch
                                )}
                            />
                            <div> {flight?.ratings?.comfortRating.pitch}%</div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
export default MyListFlightDetails
