import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Baseline from './pages/Baseline'
import FindFlight from './pages/FindFlight'
import Home from './pages/Home'
import Compare from './pages/Compare'
import MyList from './pages/MyList'
import Version from './pages/Version'
import MyListFlightDetails from './pages/MyListFlightDetails'
import {Auth0Provider} from '@auth0/auth0-react'
import config from './config'

const routes = () => {
    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            redirectUri={window.location.origin}>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/flight" element={<FindFlight />} />
                <Route path="/baseline" element={<Baseline />} />
                <Route path="/compare" element={<Compare />} />
                <Route path="/myList" element={<MyList />} />
                <Route
                    path="/flightDetails"
                    element={<MyListFlightDetails />}
                />
                <Route path="/version" element={<Version />} />
            </Routes>
        </Auth0Provider>
    )
}
export default routes
