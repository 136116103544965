import React, {useState} from 'react'
import Modal from '../modal/Modal'
import ArrowTopLeft from '../../assets/ArrowTopLeft.svg'
import ArrowBottomLeft from '../../assets/ArrowBottomLeft.svg'
import ArrowBottomRight from '../../assets/ArrowBottomRight.svg'
import ArrowTopRight from '../../assets/ArrowTopRight.svg'

const Guidance = props => {
    const [modal, setModal] = useState(true)

    const modalClose = () => {
        setModal(false)
    }
    const updateGuidance = () => {
        props.setGuidance(true)
        localStorage.setItem('guidance', true)
    }
    return (
        <Modal show={modal} handleClose={e => modalClose(e)} isGuidance={true}>
            <div className="guidance">
                <div className="guidance__menu">
                    <img src={ArrowTopLeft} className="menu-icon" />
                    <div className="menu-text">
                        Access main <br />
                        menu for more <br />
                        options
                    </div>
                </div>
                <div className="guidance__list">
                    <div className="list-text">
                        See all flights <br />
                        you saved
                    </div>
                    <img src={ArrowTopRight} className="list-icon" />
                </div>
                <div className="guidance__checkout">
                    <div className="checkout-text">
                        Checkout out <br />
                        rate details
                    </div>
                    <img src={ArrowTopRight} className="checkout-icon" />
                </div>
                <div
                    data-testid="go-it"
                    className="guidance__center"
                    onClick={e => updateGuidance()}>
                    GO IT
                </div>
                <div className="guidance__scroll">
                    <div className="scroll-text">
                        Scroll to see <br />
                        more options
                    </div>
                    <img src={ArrowTopRight} className="scroll-icon" />
                </div>
                <div className="guidance__save">
                    <img src={ArrowBottomLeft} className="save-icon" />
                    <div className="save-text">
                        Save flights
                        <br />
                        that you are <br />
                        interested on
                    </div>
                </div>
                <div className="guidance__book">
                    <div className="book-text">
                        You can also <br />
                        book your flight <br />
                        right away!
                    </div>
                    <img src={ArrowBottomRight} className="book-icon" />
                </div>
            </div>
        </Modal>
    )
}
export default Guidance
