import React, {useEffect, useState} from 'react'
import './../../styles/Carousel.scss'

const defaultProps = {
    message: 'No items here',
}

const Carousel = props => {
    props = {...defaultProps, ...props}
    const {children, frameSize, show, setVisibleOfferId, message} = props

    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)

    const [touchPosition, setTouchPosition] = useState(null)

    useEffect(() => {
        setLength(children.length)
        if (currentIndex > children.length - 1 && children.length > 0) {
            setCurrentIndex(currentIndex - 1)
            if (setVisibleOfferId) setVisibleOfferId(currentIndex - 1)
        }
    }, [children])

    const next = () => {
        if (currentIndex < length - frameSize) {
            setCurrentIndex(prevState => prevState + 1)
            if (setVisibleOfferId) setVisibleOfferId(currentIndex + 1)
        }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
            if (setVisibleOfferId) setVisibleOfferId(currentIndex - 1)
        }
    }

    const handleTouchStart = e => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = e => {
        const touchDown = touchPosition
        if (touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    return (
        <div className="carousel-container" data-testid="carousel">
            {children.length == 0 ? (
                <div className="no-item">{message}</div>
            ) : null}
            <div className="carousel-wrapper">
                {currentIndex > 0 && (
                    <button
                        onClick={prev}
                        className="left-arrow"
                        data-testid="left-arrow">
                        &lt;
                    </button>
                )}
                <div
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}>
                    <div
                        className={`carousel-content frame-size-${frameSize}`}
                        style={
                            show
                                ? {
                                      transform: `translateX(-${
                                          currentIndex * (100 / frameSize)
                                      }%)`,
                                      display: 'contents',
                                  }
                                : {
                                      transform: `translateX(-${
                                          currentIndex * (100 / frameSize)
                                      }%)`,
                                  }
                        }>
                        {children}
                    </div>
                </div>
                {currentIndex < length - frameSize && (
                    <button
                        onClick={next}
                        className="right-arrow"
                        data-testid="right-arrow">
                        &gt;
                    </button>
                )}
            </div>
        </div>
    )
}

export default Carousel
