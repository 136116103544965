const daysWithFullName = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
]
const daysWithShortName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
]

export const getFormattedDay = departureDate => {
    const date = new Date(departureDate)

    return daysWithFullName[date.getDay()]
}

export const getFormattedDayWithPartialName = departureDate => {
    const date = new Date(departureDate)
    return daysWithShortName[date.getDay()]
}

export const getFormattedMonth = departureDate => {
    const date = new Date(departureDate)
    return months[date.getMonth()] + ' ' + date.getDate()
}

export const getFormatTime = value => {
    const date = new Date(value)
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let amOrPm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ' ' + amOrPm
    return strTime
}
