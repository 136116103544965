import React from 'react'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import ProgressBar from 'react-bootstrap/ProgressBar'

const RatingBreakdown = props => {
    return (
        <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
                <Popover>
                    <Popover.Header as="h3">
                        Understand your upgrade
                    </Popover.Header>
                    <Popover.Body className="pop-body">
                        <div>
                            <div className="pop-body-content">
                                <div>Price</div>
                                {props.data.ratings.priceRating >
                                props.data.ratings.comfortRating.total ? (
                                    <div>
                                        <b>BETTER PRICE</b>
                                    </div>
                                ) : null}
                                <div>{props.data.ratings.priceRating}%</div>
                            </div>
                            <hr />
                            <div className="pop-body-content">
                                <div>Comfort</div>
                                {props.data.ratings.comfortRating.total >
                                props.data.ratings.priceRating ? (
                                    <div>
                                        <b>BETTER COMFORT</b>
                                    </div>
                                ) : null}
                                <div>
                                    {props.data.ratings.comfortRating.total}%
                                </div>
                            </div>
                            <hr />
                            <div className="pop-body-content">
                                <div>Value rating</div>
                                <div>{props.data.ratings.valueRating}%</div>
                            </div>
                        </div>
                    </Popover.Body>
                </Popover>
            }>
            <div>
                <div className="rating">
                    <button>VALUE RATING</button>
                    <div> {props.data.ratings.valueRating}%</div>
                </div>

                <ProgressBar
                    className="progress-bar"
                    variant="success"
                    now={props.data.ratings.valueRating}
                />
            </div>
        </OverlayTrigger>
    )
}

export default RatingBreakdown
